<template>
  <div class="component-settings-subscription-prices px-4">
    <b-row>
      <b-col>
        <h3 class="pt-4">{{$t(translationPath + 'title')}}</h3>

        <div v-if="!isLoaded" class="mt-5"><loader /></div>

        <!-- STATUS MESSAGES -->
        <div class="status_msg" v-if="isLoaded">
          <!-- ---------------------------- -->
          <!-- TRIAL -->
          <template v-if="apiData.subscription_status.on_trial">
            <template v-if="apiData.subscription_status.on_generic_trial">
              <!-- TRIAL :: ACTIVE GENERIC TRIAL (i.e. no subscription, user will need to create a new subscription) -->
              <b-alert show variant="warning">
                <div v-html="$t(translationPath+'status_msg.generic_trial',{days_left: apiData.days_left, period_ends_at: apiData.period_ends_at, data_will_be_deleted_at: apiData.data_will_be_deleted_at})"></div>
              </b-alert>
              <!--Action #1: SWAP Subscription (but since it's generic trial we need to create subscription (with payment method)
              Action #2: Cancel trial subscription-->
            </template>
            <template v-else>
              <!-- TRIAL :: ACTIVE TRIAL (i.e. there exists a subscription, often a partner solution (subscription with payment method and trial period)) -->
              <b-alert show variant="success">
                <div v-html="$t(translationPath+'status_msg.trial_with_subscription',{days_left: apiData.days_left, period_ends_at: apiData.period_ends_at})"></div>
              </b-alert>
              <b-row no-gutters>
                <b-col><b-link href="#" class="btn btn-sm btn-outline-primary" @click.prevent="onChangeSubscription">{{ $t(translationPath + 'btn_swap_subscription') }}</b-link></b-col>
                <b-col class="text-right"><b-link href="#" class="btn btn-sm btn-outline-secondary" @click.prevent="onCancelTrialSubscription">{{ $t(translationPath + 'btn_cancel_subscription') }}</b-link></b-col>
              </b-row>
            </template>
          </template>

          <!-- ---------------------------- -->
          <!-- START :: EXPIRED TRIAL -->
          <template v-else-if="apiData.subscription_status.has_expired_trial">
            <b-alert show variant="danger">
              <div v-html="$t(translationPath+'status_msg.expired_trial',{period_ends_at: apiData.period_ends_at, data_will_be_deleted_at: apiData.data_will_be_deleted_at })"></div>
            </b-alert>
            <template v-if="apiData.count !== 0">
              <!-- Resume subscription opened form -->
            </template>
            <template v-else-if="apiData.count === 0">
              <!--<b-link href="#" class="btn btn-sm btn-primary" @click.prevent="onCreateSubscription">{{ $t(translationPath + 'btn_resume_subscription') }}</b-link>-->
              <b-link href="#" class="btn btn-sm btn-primary" @click.prevent="createNewSubscription">{{ $t(translationPath + 'btn_resume_subscription') }}</b-link>
            </template>
          </template>
          <!-- END :: EXPIRED TRIAL -->

          <!-- ---------------------------- -->
          <!-- START :: CANCELED -->
          <template v-else-if="apiData.subscription_status.canceled">
            <template v-if="apiData.subscription_status.on_grace_period">
              <!-- CANCELED :: ON GRACE PERIOD -->
              <b-alert show variant="warning">
                <div v-html="$t(translationPath+'status_msg.canceled_on_grace_period',{period_ends_at: apiData.period_ends_at, data_will_be_deleted_at: apiData.data_will_be_deleted_at })"></div>
              </b-alert>

              <b-row no-gutters>
                <b-col><b-link href="#" class="btn btn-sm btn-outline-primary" @click.prevent="onResumeSubscription">{{ $t(translationPath + 'btn_resume_subscription') }}</b-link></b-col>
              </b-row>
            </template>
            <template v-else-if="apiData.subscription_status.ended">
              <!-- CANCELED :: ENDED-->
              <b-alert show variant="danger">
                <div v-html="$t(translationPath+'status_msg.canceled_ended',{period_ends_at: apiData.period_ends_at, data_will_be_deleted_at: apiData.data_will_be_deleted_at })"></div>
              </b-alert>
              <b-link href="#" class="btn btn-sm btn-primary" @click.prevent="createNewSubscription">{{ $t(translationPath + 'btn_resume_subscription') }}</b-link>
            </template>
          </template>
          <!-- END :: CANCELED -->

          <!-- ---------------------------- -->
          <!-- NORMAL SUBSCRIPTION -->
          <template v-else-if="apiData.subscription_status.has_stripe_subscription">
            <template v-if="oActiveSubscription.stripe_status === 'past_due'">
              <b-alert show variant="warning">
                <div v-html="$t(translationPath+'status_msg.past_due',{days_left: apiData.days_left, period_ends_at: apiData.period_ends_at})"></div>
              </b-alert>
            </template>
            <template v-else-if="apiData.subscription_status.recurring">
              <!-- RECURRING -->
              <b-alert show variant="success">
                <div v-html="$t(translationPath+'status_msg.active_recurring',{days_left: apiData.days_left, period_ends_at: apiData.period_ends_at})"></div>
              </b-alert>

              <b-row no-gutters>
                <b-col><b-link href="#" class="btn btn-sm btn-outline-primary" @click.prevent="onChangeSubscription">{{ $t(translationPath + 'btn_swap_subscription') }}</b-link></b-col>
                <b-col class="text-right"><b-link href="#" class="btn btn-sm btn-outline-secondary" @click.prevent="onCancelSubscription">{{ $t(translationPath + 'btn_cancel_subscription') }}</b-link></b-col>
              </b-row>
            </template>
            <template v-else>
              <!-- NOT RECURRING-->
              <b-alert show variant="warning">
                <div v-html="$t(translationPath+'status_msg.not_recurring', {ends_at: apiData.period_ends_at})"></div>
              </b-alert>

              <b-row no-gutters>
                <b-col><b-link href="#" class="btn btn-sm btn-outline-primary" @click.prevent="onResumeSubscription">{{ $t(translationPath + 'btn_resume_subscription') }}</b-link></b-col>
              </b-row>
            </template>
          </template>

          <template v-else>
            <b-alert show variant="warning">
              <div v-html="$t(translationPath+'status_msg.not_active_no_subscription',{days_left: apiData.days_left, period_ends_at: apiData.period_ends_at})"></div>
            </b-alert>
            <b-link href="#" class="btn btn-sm btn-primary" @click.prevent="createNewSubscription">{{ $t(translationPath + 'btn_resume_subscription') }}</b-link>
          </template>
        </div>

        <!-- SUBSCRIPTION SELECT FORM -->
        <div v-if="isChangeSubscriptionFormShown">
          <b-row no-gutters>
            <b-col cols="12">
              <h3 class="pt-5 pb-0 mb-0">{{ $t( translationPath + 'label_subscription_price') }}</h3>
              <p class="pb-0 mb-0">{{ $t( translationPath + 'label_subscription_price_description') }}</p>
              <b-form-group
                label-class="align-self-center pr-md-0 pr-lg-4 text-black"
                label-for="subscription_price"
                label-cols="12"
                label-align="left"
                class=""
                :invalid-feedback="invalidSelectedSubscriptionPriceFeedback"
              >
                <b-form-select
                  id="subscription_price"
                  class="rounded-pill"
                  v-model="selectedSubscriptionPrice"
                  :options="aSubscriptionPriceOptions"
                  :state="$v.selectedSubscriptionPrice.$dirty ? !$v.selectedSubscriptionPrice.$error : null"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <template v-if="apiData.payment_methods_count !== 0 && bChangeSubscriptionFormCreateAction">
            <b-row no-gutters>
              <b-col cols="12">
                <h3 class="pb-0 mb-0">{{ $t( translationPath + 'label_payment_methods') }}</h3>
                <p class="pb-0 mb-0">{{ $t( translationPath + 'label_payment_methods_description') }}</p>
                <b-form-group
                  label-class="align-self-center pr-md-0 pr-lg-4 text-black"
                  label-for="payment_method"
                  label-cols="12"
                  label-align="left"
                  class=""
                  :invalid-feedback="invalidSelectedPaymentMethodFeedback"
                >
                  <b-form-select
                    id="payment_method"
                    class="rounded-pill"
                    v-model="selectedPaymentMethod"
                    :options="aPaymentMethodOptions"
                    :state="$v.selectedPaymentMethod.$dirty ? !$v.selectedPaymentMethod.$error : null"
                  ></b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </template>
          <template v-if="this.apiData.payment_methods_count === 0 || bShowAddPaymentMethodForm">
            <h3 class="pb-0 mb-0">{{ $t( translationPath + 'label_payment_methods_add_card') }}</h3>
            <p class="pb-0 mb-0">{{ $t( translationPath + 'label_payment_methods_add_card_description') }}</p>
            <div id="card-element1" class="form-control rounded-pill my-2 pt-2" :class="stripeCardElementExtraClass"></div>
            <div class="invalid-feedback">{{ stripeValidationError }}</div>
          </template>

          <b-row no-gutters>
            <b-col cols="12" md="4" lg="5">
              <template v-if="bChangeSubscriptionFormCreateAction">
                <b-link v-if="stripe.cardElement || apiData.payment_methods_count" href="#" class="btn btn-sm btn-primary" @click.prevent="doCreateSubscription">{{ $t(translationPath + 'btn_create_subscription') }}</b-link>
              </template>
              <template v-if="bChangeSubscriptionFormChangeAction">
                <b-link href="#" class="btn btn-sm btn-primary" @click.prevent="onSwapSubscription">{{ $t(translationPath + 'btn_do_swap_subscription') }}</b-link>
              </template>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="12" class="mt-4">
              <hr />
            </b-col>
          </b-row>
        </div>

        <!-- DETAILED INFORMATION -->
        <div v-if="isLoaded && oActiveSubscription && oActiveSubscription.stripe_status !== 'canceled'" class="pt-3">
          <h3 class="pt-4">{{$t(translationPath + 'detailed_info')}}</h3>

          <b-row no-gutters>
            <b-col class="label col-md-3">{{$t(translationPath + 'label_subscription_type')}}:</b-col>
            <b-col>{{ oActiveSubscription.spirecta_months === 1 ? $t( translationPath + 'subscription_monthly') : $t(translationPath + 'subscription_annual') }}</b-col>
          </b-row>
          <b-row no-gutters>
            <b-col class="label col-md-3">{{$t(translationPath + 'label_period')}}:</b-col>
            <b-col>{{$t(translationPath + 'period', {period_start: oActiveSubscription.period_start, period_end: oActiveSubscription.period_end})}}</b-col>
          </b-row>
          <b-row no-gutters>
            <b-col class="label col-md-3">{{$t(translationPath + 'label_days_left')}}:</b-col>
            <b-col>{{$t(translationPath + 'days_left', {days_left: oActiveSubscription.days_left > 0 ? oActiveSubscription.days_left : 0})}}</b-col>
          </b-row>
          <b-row no-gutters class="pt-3">
            <b-col class="label col-md-3">{{$t(translationPath + 'label_status')}}:</b-col>
            <b-col>{{ $t(translationPath + 'status_' + oActiveSubscription.stripe_status, {partner_name: oActiveSubscription.partner_name})}}</b-col>
          </b-row>
          <b-row no-gutters>
            <b-col class="label col-md-3">{{$t(translationPath + 'label_amount')}}:</b-col>
            <b-col>{{oActiveSubscription.spirecta_amount}} {{oActiveSubscription.spirecta_currency}} <span v-if="oActiveSubscription.spirecta_plan_type !== '12month' && oActiveSubscription.spirecta_plan_type !== '1month'">{{$t(translationPath + 'after_trial_period')}}</span></b-col>
          </b-row>
        </div>

        <div v-if="(inDevelopmentMode || $route.query.debug) && (apiData && isLoaded)" class="alert alert-info mt-5 pt-4">
          <h4>DEVELOPMENT / DEBUG INFORMATION</h4>

          <p><b>Description</b>: "{{apiData.subscription_description}}" ("{{apiData.subscription_constant}}")</p>

          <b>apiData.subscription_status.</b>
          <ul>
            <li>Active
              <ul>
                <li><b>subscribed:</b> {{apiData.subscription_status.subscribed}}</li>
                <li><b>recurring:</b> {{apiData.subscription_status.recurring}}</li>
              </ul>
            </li>
            <li>Trial
              <ul>
                <li><b>on_trial:</b> {{apiData.subscription_status.on_trial}}</li>
                <li><b>on_generic_trial:</b> {{apiData.subscription_status.on_generic_trial}}</li>
                <li><b>has_expired_trial:</b> {{apiData.subscription_status.has_expired_trial}}</li>
              </ul>
            </li>
            <li>Canceled
              <ul>
                <li><b>canceled:</b> {{apiData.subscription_status.canceled}}</li>
                <li><b>on_grace_period:</b> {{apiData.subscription_status.on_grace_period}}</li>
                <li><b>ended:</b> {{apiData.subscription_status.ended}}</li>
              </ul>
            </li>
          </ul>

          <div>
            <b>User</b>
            <ul>
              <li><b>Stripe customer id:</b> <a :href="'https://dashboard.stripe.com/'+(apiData.stripe_livemode ? '' : 'test/')+'customers/' + apiData.stripe_customer_id" target="_blank" class="text-red">{{apiData.stripe_customer_id}} (open in Stripe)</a></li>
              <li><b>Spirecta User ID:</b> <a :href="(apiData.stripe_livemode ? 'https://api.spirecta.com' : 'http://api.spirecta.test')+'/nova/resources/users/'+this.currentUser.id" target="_blank" class="text-red">{{this.currentUser.id}} (open in Nova)</a></li>
            </ul>
          </div>

          <div v-if="oActiveSubscription">
            <b>subscription</b>
            <ul>
              <li><b>id:</b> {{oActiveSubscription.id}}</li>
              <li><b>stripe_status:</b> {{oActiveSubscription.stripe_status}}</li>
              <li><b>stripe_id:</b> <a :href="'https://dashboard.stripe.com/'+(apiData.stripe_livemode ? '' : 'test/')+'/subscriptions/' + oActiveSubscription.stripe_id" target="_blank" class="text-red">{{oActiveSubscription.stripe_id}} (open in Stripe Dashboard)</a></li>
              <li><b>stripe_current_period_start:</b> {{oActiveSubscription.stripe_current_period_start}}</li>
              <li><b>stripe_current_period_end:</b> {{oActiveSubscription.stripe_current_period_end}}</li>
              <li><b>spirecta_plan_type:</b> {{oActiveSubscription.spirecta_plan_type}}</li>
              <li><b>trial_ends_at:</b> {{oActiveSubscription.trial_ends_at}}</li>
              <li><b>ends_at:</b> {{oActiveSubscription.ends_at}}</li>
            </ul>
          </div>

          <b>Form Status</b>
          <ul>
            <li>bChangeSubscriptionFormCreateAction: {{ bChangeSubscriptionFormCreateAction }}</li>
            <li>bChangeSubscriptionFormChangeAction: {{ bChangeSubscriptionFormChangeAction }}</li>
          </ul>

        </div>

      </b-col>
    </b-row>

    <!-- CANCEL SUBSCRIPTION MODAL -->
    <confirm-modal
      ref="ConfirmCancelSubscriptionModal"
      variant="danger"
      v-if="isLoaded"
      :title="translationPath + 'modal_confirm_cancel_subscription_title'"
      :message="$t(translationPath + 'modal_confirm_cancel_subscription_message',{period_ends_at: apiData.period_ends_at, data_will_be_deleted_at: apiData.data_will_be_deleted_at}).toString()"
      :yes-caption="translationPath + 'modal_confirm_cancel_subscription_btn_yes'"
      :no-caption="translationPath + 'modal_confirm_cancel_subscription_btn_no_cancel'"
      @confirmed="doCancelSubscription"
    />

    <!-- CANCEL TRIAL SUBSCRIPTION MODAL -->
    <confirm-modal
      ref="ConfirmCancelTrialSubscriptionModal"
      variant="danger"
      v-if="isLoaded"
      :title="translationPath + 'modal_confirm_cancel_trial_subscription_title'"
      :message="$t(translationPath + 'modal_confirm_cancel_trial_subscription_message',{period_ends_at: apiData.period_ends_at, data_will_be_deleted_at: apiData.data_will_be_deleted_at}).toString()"
      :yes-caption="translationPath + 'modal_confirm_cancel_subscription_btn_yes'"
      :no-caption="translationPath + 'modal_confirm_cancel_subscription_btn_no_cancel'"
      @confirmed="doCancelSubscription"
    />

    <!-- RESUME SUBSCRIPTION MODAL -->
    <confirm-modal
      ref="ConfirmResumeSubscriptionModal"
      variant="primary"
      :title="translationPath + 'modal_confirm_resume_subscription_title'"
      :message="$t(translationPath + 'modal_confirm_resume_subscription_message').toString()"
      :yes-caption="translationPath + 'modal_confirm_resume_subscription_btn_yes'"
      @confirmed="doResumeSubscription"
    />

    <!-- CREATE SUBSCRIPTION MODAL -->
    <confirm-modal
      ref="ConfirmCreateSubscriptionModal"
      variant="primary"
      :title="translationPath + 'modal_confirm_resume_subscription_title'"
      :message="$t(translationPath + 'modal_confirm_resume_subscription_message').toString()"
      :yes-caption="translationPath + 'modal_confirm_create_subscription_btn_yes'"
      @confirmed="doCreateSubscription"
    />

    <!-- SWAP SUBSCRIPTION MODAL -->
    <confirm-modal
      ref="ConfirmSwapSubscriptionModal"
      variant="primary"
      :title="translationPath + 'modal_confirm_swap_subscription_title'"
      :message="$t(translationPath + 'modal_confirm_swap_subscription_message', { plan: selectedPriceLabel }).toString()"
      :yes-caption="translationPath + 'modal_confirm_swap_subscription_btn_yes'"
      @confirmed="doSwapSubscription"
    />
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import stripeMixin from './common/stripeMixin'
import Loader from '@/components/common/Loader'
import ConfirmModal from '@/components/modals/ConfirmModal'
import { mapState, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'SubscriptionPrices',
  components: { Loader, ConfirmModal },
  mixins: [stripeMixin],
  data () {
    return {
      translationPath: 'settings.subscription-v2.subscription_prices.',
      isLoaded: false,
      oActiveSubscription: null,
      isChangeSubscriptionFormShown: false,
      selectedSubscriptionPrice: null,
      aSubscriptionPriceOptions: [],
      bIsStripeRequestInProcess: false,
      bChangeSubscriptionFormCreateAction: false,
      bChangeSubscriptionFormChangeAction: false,
      bShowAddPaymentMethodForm: false,
      selectedPaymentMethod: null,
      aPaymentMethodOptions: [],
      apiData: {}
    }
  },
  validations: {
    selectedSubscriptionPrice: { required },
    selectedPaymentMethod: { required },
    createSubscriptionForm: ['selectedSubscriptionPrice', 'selectedPaymentMethod'],
    changeSubscriptionForm: ['selectedSubscriptionPrice']
  },
  created () {
    this.loadData()
  },
  computed: {
    ...mapState('user', ['currentUser']),
    inDevelopmentMode () {
      return process.env.NODE_ENV === 'development'
    },
    invalidSelectedSubscriptionPriceFeedback () {
      if (this.$v.selectedSubscriptionPrice.required === false) { return this.$t('common.errors.required') }
      return ''
    },
    invalidSelectedPaymentMethodFeedback () {
      if (this.$v.selectedPaymentMethod.required === false) { return this.$t('common.errors.required') }
      return ''
    },
    selectedPriceLabel () {
      if (!this.selectedSubscriptionPrice) {
        return ''
      }

      let str = ''
      for (const i in this.aSubscriptionPriceOptions) {
        if (this.aSubscriptionPriceOptions[i].value === this.selectedSubscriptionPrice) {
          str = this.aSubscriptionPriceOptions[i].text
          break
        }
      }
      return str
    }
  },
  methods: {
    ...mapActions('user', ['fetchData']),
    async loadData () {
      this.isLoaded = false
      this.aSubscriptionPriceOptions = []
      this.aPaymentMethodOptions = []
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_ROOT_API}/subscriptions-v2`)
          .then(response => {
            this.apiData = response.data.data

            if (this.apiData.subscription_status.has_stripe_subscription) {
              this.oActiveSubscription = this.apiData.subscription

              this.oActiveSubscription.ends_at = this.oActiveSubscription.ends_at ? moment(this.oActiveSubscription.ends_at).format('YYYY-MM-DD') : null
              this.oActiveSubscription.period_start = moment(this.oActiveSubscription.stripe_current_period_start).format('YYYY-MM-DD')
              this.oActiveSubscription.period_end = moment(this.oActiveSubscription.stripe_current_period_end).format('YYYY-MM-DD')
              this.oActiveSubscription.days_left = moment(this.oActiveSubscription.stripe_current_period_end).diff(moment(), 'days')
            }

            this.apiData.subscription_prices.map(item => {
              if ((this.oActiveSubscription && item.months !== this.oActiveSubscription.spirecta_months) || this.apiData.subscription_status.ended || !this.oActiveSubscription) {
                this.aSubscriptionPriceOptions.push({ value: item.stripe_price_id, text: this.$t(this.translationPath + 'prices.' + item.nickname) })
              }
            })
            if (this.aSubscriptionPriceOptions.length) {
              this.selectedSubscriptionPrice = this.aSubscriptionPriceOptions[0].value
            }

            this.apiData.payment_methods.map(item => {
              this.aPaymentMethodOptions.push({ value: item.stripe_payment_method_id, text: this.$t(this.translationPath + 'card_name_opt', { brand: item.brand, last4: item.last4, exp_month: item.exp_month.toString().padStart(2, '0'), exp_year: item.exp_year }) })
            })

            if (
              this.apiData.subscription_status.on_generic_trial ||
              this.apiData.subscription_constant === 'not_active_no_trial_no_subscription' ||
              this.apiData.subscription_status.has_expired_trial
            ) {
              this.isChangeSubscriptionFormShown = true
              this.bChangeSubscriptionFormCreateAction = true
              this.bChangeSubscriptionFormChangeAction = false

              Promise.all([
                this.loadStripeSetupIntent(),
                this.includeStripe()
              ])
                .then(() => {
                  this.initCardElement()
                })
                .catch(err => {
                  console.error(err)
                })
            }

            this.$emit('subscription-status', this.apiData.subscription_status)

            this.isLoaded = true
            resolve(response.data.data)
          })
          .catch(err => {
            console.error(err)
            reject(err)
          })
      })
    },
    onCancelSubscription () {
      this.$refs.ConfirmCancelSubscriptionModal.show()
    },
    onCancelTrialSubscription () {
      this.$refs.ConfirmCancelTrialSubscriptionModal.show()
    },
    doCancelSubscription () {
      this.isLoaded = false
      this.isChangeSubscriptionFormShown = false
      this.$refs.ConfirmCancelSubscriptionModal.hide()
      axios.post(`${process.env.VUE_APP_ROOT_API}/subscriptions-v2/cancel-subscription`)
        .then(response => {
          this.loadData()
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.cancel_subscription_success').toString(), {
            title: this.$t(this.translationPath + 'toast.cancel_subscription_title').toString(),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.cancel_subscription_fail').toString(), {
            title: this.$t(this.translationPath + 'toast.cancel_subscription_title').toString(),
            variant: 'danget',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
    },
    onCreateSubscription () {
      this.$refs.ConfirmCreateSubscriptionModal.show()
    },
    async doCreateSubscription () {
      if (this.bIsStripeRequestInProcess) {
        return false
      }

      this.bIsStripeRequestInProcess = true
      if (this.apiData.payment_methods_count) {
        this.$v.createSubscriptionForm.$touch()
        if (this.$v.createSubscriptionForm.$invalid) {
          this.bIsStripeRequestInProcess = false
          return false
        }
      } else {
        this.$v.changeSubscriptionForm.$touch()
        if (this.$v.changeSubscriptionForm.$invalid) {
          this.bIsStripeRequestInProcess = false
          return false
        }
      }

      let error = null
      let setupIntent = null
      if (!this.apiData.payment_methods_count) {
        const confirmCardSetup = await this.stripe.stripe.confirmCardSetup(
          this.stripeSetupIntent.client_secret, {
            payment_method: {
              card: this.stripe.cardElement,
              billing_details: { name: 'noname' }
            }
          }
        )
        if (confirmCardSetup.setupIntent) {
          setupIntent = confirmCardSetup.setupIntent
        }
        if (confirmCardSetup.error) {
          error = confirmCardSetup.error
        }
      }

      if (error) {
        this.bIsStripeRequestInProcess = false
        if (error.type === 'validation_error') {
          this.stripeCardElementExtraClass = 'is-invalid'
          this.stripeValidationError = error.message
        } else {
          console.error(error)
        }
      } else {
        this.$bvToast.toast(this.$t(this.translationPath + 'toast.create_subscription_info').toString(), {
          title: this.$t(this.translationPath + 'toast.create_subscription_title').toString(),
          variant: 'info',
          solid: true,
          'auto-hide-delay': 3000
        })
        if (setupIntent) {
          this.selectedPaymentMethod = setupIntent.payment_method
        }

        const postData = {
          price_id: this.selectedSubscriptionPrice,
          payment_method_id: this.selectedPaymentMethod
        }
        axios.post(`${process.env.VUE_APP_ROOT_API}/subscriptions-v2/create-subscription`, postData)
          .then(response => {
            if (this.stripe.cardElement) {
              this.stripe.cardElement.clear()
            }
            this.loadData()
              .then(() => {
                this.apiData.payment_methods_count++ // We added a payment method, but it is not synced yet
              })
            this.$bvToast.toast(this.$t(this.translationPath + 'toast.create_subscription_success').toString(), {
              title: this.$t(this.translationPath + 'toast.create_subscription_title').toString(),
              variant: 'success',
              solid: true,
              'auto-hide-delay': 3000
            })
          })
          .catch(err => {
            console.error(err)
            this.$bvToast.toast(this.$t(this.translationPath + 'toast.create_subscription_fail').toString(), {
              title: this.$t(this.translationPath + 'toast.create_subscription_title').toString(),
              variant: 'danger',
              solid: true,
              'auto-hide-delay': 3000
            })
          })
          .then(() => {
            this.bIsStripeRequestInProcess = false
            this.bChangeSubscriptionFormCreateAction = false
            this.bChangeSubscriptionFormChangeAction = false
            this.isChangeSubscriptionFormShown = false
            this.fetchData()
          })
      }
    },
    onResumeSubscription () {
      this.$refs.ConfirmResumeSubscriptionModal.show()
    },
    doResumeSubscription () {
      this.isLoaded = false
      this.$refs.ConfirmResumeSubscriptionModal.hide()
      axios.post(`${process.env.VUE_APP_ROOT_API}/subscriptions-v2/resume-subscription`)
        .then(response => {
          this.loadData()
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.resume_subscription_success').toString(), {
            title: this.$t(this.translationPath + 'toast.resume_subscription_title').toString(),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.cancel_subscription_fail').toString(), {
            title: this.$t(this.translationPath + 'toast.resume_subscription_title').toString(),
            variant: 'danget',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
    },
    createNewSubscription () {
      this.isChangeSubscriptionFormShown = !this.isChangeSubscriptionFormShown
      this.bChangeSubscriptionFormCreateAction = true
      this.bChangeSubscriptionFormChangeAction = false
    },
    onChangeSubscription () {
      this.isChangeSubscriptionFormShown = !this.isChangeSubscriptionFormShown
      this.bChangeSubscriptionFormCreateAction = false
      this.bChangeSubscriptionFormChangeAction = true
    },
    onSwapSubscription () {
      this.$v.changeSubscriptionForm.$touch()
      if (this.$v.changeSubscriptionForm.$invalid) {
        return false
      }

      this.$refs.ConfirmSwapSubscriptionModal.show()
    },
    doSwapSubscription () {
      this.isLoaded = false
      this.isChangeSubscriptionFormShown = false
      this.$refs.ConfirmSwapSubscriptionModal.hide()

      const postData = {
        plan: this.selectedSubscriptionPrice,
        payment_method: this.selectedPaymentMethod
      }

      axios.post(`${process.env.VUE_APP_ROOT_API}/subscriptions-v2/swap-subscription`, postData)
        .then(response => {
          this.loadData()
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.swap_subscription_success').toString(), {
            title: this.$t(this.translationPath + 'toast.swap_subscription_title').toString(),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
        .catch(err => {
          console.error(err)
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.swap_subscription_fail').toString(), {
            title: this.$t(this.translationPath + 'toast.swap_subscription_title').toString(),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
    }
  }
}
</script>

<style lang="scss">
.component-settings-subscription-prices{
  .label{
    font-weight: 700;
  }
}
</style>
